import React, { useState, useEffect } from 'react';
import './App.css';
import { readAndCompressImage } from 'browser-image-resizer';
var saveAs = require('./save-as');

function App() {

  const [selectedFile, setSelectedFile] = useState({name: ''});
  // const [isSelected, setIsSelected] = useState(false);

  const [imageBlob, setImageBlob] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [imageBytes, setImageBytes] = useState(0);
  const [originalImageWidth, setOriginalImageWidth] = useState();
  const [originalImageHeight, setOriginalImageHeight] = useState();

  const [processingFlag, setProcessingFlag] = useState(false);
  const [sliderPercent, setSliderPercent] = useState(100);

  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    const imageUrl = "nyc.jpg";

    fetch(imageUrl)
      .then(response => response.blob())
      .then(imageBlob => {        
        changeHandler({
          target: {
            files: [
              imageBlob
            ]
          }
        });

      });
      
  },[]);





  const changeHandler = (event) => {

    window.gtag('file_button_clicked', { action: 'file_loaded'});

    setSelectedFile(event.target.files[0]);
    // setIsSelected(true);

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = (function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
      image.onload = function () {
        let imageB64 = reader.result;
        
        let stringLength = imageB64.length - 'data:image/png;base64,'.length;
        let sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
        let sizeInKb = sizeInBytes / 1024;

        setImageWidth(this.width);
        setOriginalImageWidth(this.width)
        setImageHeight(this.height);
        setOriginalImageHeight(this.height);
        setImageBlob(reader.result);
        setImageBytes(sizeInKb.toFixed(3));
        setSliderPercent(100);
      };
    });
  };

  const convertToBase64 = blob => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const resize = async (value) => {
    let width = Math.trunc(originalImageWidth * (value / 100));
    let height = Math.trunc(originalImageHeight * (value / 100));
    setImageWidth(width);
    setImageHeight(height);
    setSliderPercent(value);
  };

  const processImage = async () => {
    const config = {
      quality: 1.0,
      maxWidth: imageWidth,
      maxHeight: imageHeight,
      autoRotate: false,
      debug: false
    };
    let image = await readAndCompressImage(selectedFile, config);
    let base64Image = await convertToBase64(image);

    let stringLength = base64Image.length - 'data:image/png;base64,'.length;
    let sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
    let sizeInKb = sizeInBytes / 1024;

    setImageBytes(sizeInKb.toFixed(3));

    await setImageBlob(oldImage => {
      return (base64Image);
    });
    setProcessingFlag(false);
  };

  const download = () => {
    saveAs(imageBlob, 'filename');
    window.gtag('download_button_clicked', { action: 'downloaded'});
  }

  return (
    <div>
      <div className="rows border-primary card mb-3 p-3">
        <form>
          <fieldset>
            
            <div className="form-group">
              <label htmlFor="formFile" className="form-label mt-4">Load the image file to resize:</label>
              <input className="form-control" accept="image/*" type="file" id="formFile" aria-label='Open an image file for editing from your computer.' onChange={(e) => {changeHandler(e); setButtonClicked(true);}} />
            </div>
            <div className="col-12">
              <fieldset className="form-group">
                <legend className="mt-4"><strong>Resize percentage (use the slider)</strong></legend>
                <label htmlFor="customRange1" className="form-label">Percentage Value: {sliderPercent}</label>
                <input type="range" className="form-range" id="customRange1"
                  min={10}
                  max={100}
                  value={sliderPercent}
                  onChange={(e) => resize(e.target.value)}
                  onMouseDown={(e) => { setProcessingFlag(true); }}
                  onMouseUp={(e) => { processImage() }}
                  aria-label={sliderPercent}
                />
              </fieldset>
            </div>
          </fieldset>
        </form>
      </div>
      <div className="rows pt-1">
        <div className="card mb-3">
          <h3 className="card-header">{(!buttonClicked)? <strong>This is an example.</strong>: <strong>Preview</strong>} </h3>
          <div className="card-body">
            <h4 className="card-title">File Name: {selectedFile.name}</h4>
            <h4 className="card-subtitle "><strong>Width: {imageWidth} Height: {imageHeight}</strong></h4>
            <h4 className="card-subtitle "><strong>File Size {imageBytes} KB</strong></h4>
          </div>
          <div className="mx-auto ">
            {(!processingFlag) ? <img src={imageBlob} alt={selectedFile.name} aria-label='User selected image.' /> : 'Loading ...'}
          </div>
          <div className="card-body">
            <p className="card-text">Click the button below to download.</p>
          </div>
          <div className="card-footer text-muted">
            { (!processingFlag) ? <button type="button" aria-label='Download the edited image.' onClick={(e) => download()} className="btn btn-primary btn-lg">Download</button> : '' }
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;